import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import EquipmentCard from "./component/equipment_card";
import QuoteCard from "./component/quote_card";
import { IoCloseSharp } from "react-icons/io5";
import { BsReceipt } from "react-icons/bs";
import Aos from "aos";
import Filters from "./component/filters";
// src/data/equipmentData.js

export const equipmentData = [
  {
    index: 1,
    titulo: "Equipo de Elevanción Genie GS-1930",
    marca: "Genie",
    modelo: "GS-1930",
    aditamento: "Altura de trabajo máx.: 25 ft | 7,79 m, Capacidad de plataforma: 500 lb | 227 kg, Anchura de la máquina: 2 ft 6 in | 0,76 m, Longitud de la máquina: 6 ft | 1,83 m",
    precio: "N/A",
    dimensiones: "Alto: N/A, Ancho: 2 ft 6 in | 0,76 m, Largo: 6 ft | 1,83 m",
    foto: "img/maquinaria/GS-1930.webp",
    categoria: "Equipo de Elevanción"
  },
  {
    index: 2,
    titulo: "Equipo de Elevanción Genie GS-2632",
    marca: "Genie",
    modelo: "GS-2632",
    aditamento: "Altura de trabajo máx.: 32 ft 1 in | 9,80 m, Capacidad de plataforma: 500 lb | 227 kg, Anchura de la máquina: 2 ft 8 in | 0,81 m, Longitud de la máquina: 8 ft | 2,44 m, Peso: 4.729 lb | 2.145 kg",
    precio: "N/A",
    dimensiones: "Alto: N/A, Ancho: 2 ft 8 in | 0,81 m, Largo: 8 ft | 2,44 m",
    foto: "img/maquinaria/GS-2632.webp",
    categoria: "Equipo de Elevanción"
  },
  {
    index: 3,
    titulo: "Bailarina Compactadora Weber con motor Honda 4HP GX120R",
    marca: "Weber Mt",
    modelo: "SRV-660 II",
    aditamento: "Motor Honda 4.0 H.P. Gasolina KRWF, Fuerza de impacto: 20.9 kN, Origen: Hecho en Alemania, Arranque: Retráctil, Alerta de Aceite: Sí, Hodómetro: De serie, Peso: 70 kg, Capacidad de Combustible: 3.1L",
    precio: "N/A",
    dimensiones: "Alto: 100 cm, Ancho: 40 cm, Largo: 70 cm",
    foto: "img/maquinaria/SRV-660II.webp",
    categoria: "Compactadora"
  },
  {
    index: 4,
    titulo: "Revolvedora de Concreto Joper R100TB de un saco (Foreman)",
    marca: "Joper",
    modelo: "R100TB",
    aditamento: "Capacidad: 1 saco, Llanta RIN 12, Motor: Foreman 16 HP, Capacidad máxima: 360 Lts.",
    precio: "N/A",
    dimensiones: "Alto: 55″, Ancho: 42″, Largo: 72″",
    foto: "img/maquinaria/R100TB_Foreman.webp",
    categoria: "Revolvedora"
  },
  {
    index: 5,
    titulo: "Revolvedora de Concreto Joper R100TB de un saco (Honda)",
    marca: "Joper",
    modelo: "R100TB",
    aditamento: "Capacidad: 1 saco, Llanta RIN 12, Motor: Honda 9 HP, Capacidad máxima: 360 Lts.",
    precio: "N/A",
    dimensiones: "Alto: 55″, Ancho: 42″, Largo: 72″",
    foto: "img/maquinaria/R100TB_Honda.webp",
    categoria: "Revolvedora"
  },
  {
    index: 6,
    titulo: "Demoledor de Concreto Bosch de 27 Kg",
    marca: "Bosch",
    modelo: "061130A0G0",
    aditamento: "Peso: 31.74 kg, Velocidad Variable: No, Potencia de la herramienta: 2000 W",
    precio: "N/A",
    dimensiones: "Alto: 80 cm, Ancho: 74.5 cm, Largo: 16.5 cm",
    foto: "img/maquinaria/Bosch_gsh.webp",
    categoria: "Demoledor"
  },
  {
    index: 7,
    titulo: "Rotomartillo Makita HR2470 con 800 W de potencia 220 V",
    marca: "Makita",
    modelo: "HR2470",
    aditamento: "Tipo de alimentación: Con cable, Tipo: Martillo perforador, Peso: 2.9 kg",
    precio: "N/A",
    dimensiones: "Alto: 21.4 cm, Ancho: 8.4 cm, Largo: 36.0 cm",
    foto: "img/maquinaria/HR2470.webp",
    categoria: "Rotomartillo"
  },
  {
    index: 8,
    titulo: "Generador de Gasolina Evans 8500",
    marca: "Evans",
    modelo: "8500",
    aditamento: "Potencia Nominal: 8500W, Capacidad de tanque : 25 Litros, Autonomía: 10 Horas, Voltaje: 120/240V, Frecuencia: 60 Hz, Peso: 115 kg, Nivel de Ruido: 75 dB, Arranque: Eléctrico y manual",
    precio: "N/A",
    dimensiones: "Alto: 66 cm, Ancho: 52 cm, Largo: 70 cm",
    foto: "img/maquinaria/Evans_8500.webp",
    categoria: "Generador"
  },
  // Nuevos equipos añadidos
  {
    index: 9,
    titulo: "Generador de Gasolina Evans 7500",
    marca: "Evans",
    modelo: "7500",
    aditamento: "Potencia Nominal: 7500W, Capacidad de tanque: 20 Litros, Autonomía: 8 Horas, Voltaje: 120/240V, Frecuencia: 60 Hz, Peso: 100 kg, Nivel de Ruido: 70 dB, Arranque: Eléctrico y manual",
    precio: "N/A",
    dimensiones: "Alto: 62 cm, Ancho: 48 cm, Largo: 68 cm",
    foto: "img/maquinaria/Evans_7500.webp",
    categoria: "Generador"
  },
  {
    index: 10,
    titulo: "Compresor de Aire Evans 108 L, 2HP",
    marca: "Evans",
    modelo: "108 L, 2HP",
    aditamento: "Capacidad del tanque: 108 L, Potencia: 2 HP, Tipo: Pistón, Voltaje: 220V, Peso: 60 kg",
    precio: "N/A",
    dimensiones: "Alto: 80 cm, Ancho: 40 cm, Largo: 80 cm",
    foto: "img/maquinaria/Compresor_Aire_Evans.webp",
    categoria: "Compresor"
  },
  {
    index: 11,
    titulo: "Cortadora de Metal Dewalt 14\"",
    marca: "Dewalt",
    modelo: "14\"",
    aditamento: "Diámetro del disco: 14\", Potencia: 2.5 HP, Tipo de corte: Radial, Peso: 20 kg",
    precio: "N/A",
    dimensiones: "Alto: 40 cm, Ancho: 45 cm, Largo: 60 cm",
    foto: "img/maquinaria/Cortadora_Metal_Dewalt.webp",
    categoria: "Cortadora"
  },
  {
    index: 12,
    titulo: "Esmeriladora Angular Dewalt 7\"",
    marca: "Dewalt",
    modelo: "7\"",
    aditamento: "Diámetro del disco: 7\", Potencia: 1.8 HP, Velocidad: 8000 RPM, Peso: 2.5 kg",
    precio: "N/A",
    dimensiones: "Alto: 25 cm, Ancho: 15 cm, Largo: 30 cm",
    foto: "img/maquinaria/Esmeriladora_Dewalt.webp",
    categoria: "Esmeriladora"
  },
  {
    index: 13,
    titulo: "Esmeriladora Angular Makita 7\"",
    marca: "Makita",
    modelo: "7\"",
    aditamento: "Diámetro del disco: 7\", Potencia: 1.9 HP, Velocidad: 8500 RPM, Peso: 2.6 kg",
    precio: "N/A",
    dimensiones: "Alto: 26 cm, Ancho: 16 cm, Largo: 31 cm",
    foto: "img/maquinaria/Esmeriladora_Makita.webp",
    categoria: "Esmeriladora"
  },
  {
    index: 14,
    titulo: "Vibrador de Concreto Eléctrico Xtremepowerus 1HP",
    marca: "Xtremepowerus",
    modelo: "1HP",
    aditamento: "Potencia: 1 HP, Longitud del cable: 3 m, Tipo: Vibrador de inmersión, Peso: 4 kg",
    precio: "N/A",
    dimensiones: "Alto: 35 cm, Ancho: 10 cm, Largo: 80 cm",
    foto: "img/maquinaria/Vibrador_Concreto_Xtremepowerus.webp",
    categoria: "Vibrador de Concreto"
  },
  {
    index: 15,
    titulo: "Rotomartillo Hilti TE 2",
    marca: "Hilti",
    modelo: "TE 2",
    aditamento: "Tipo: Rotomartillo, Potencia: 600 W, Peso: 2.5 kg, Tipo de alimentación: Con cable",
    precio: "N/A",
    dimensiones: "Alto: 22 cm, Ancho: 10 cm, Largo: 30 cm",
    foto: "img/maquinaria/Rotomartillo_Hilti_TE2.webp",
    categoria: "Rotomartillo"
  },
  {
    index: 16,
    titulo: "Rotomartillo Makita SDS Plus",
    marca: "Makita",
    modelo: "SDS Plus",
    aditamento: "Tipo: Rotomartillo, Potencia: 800 W, Peso: 3.2 kg, Tipo de alimentación: Con cable",
    precio: "N/A",
    dimensiones: "Alto: 24 cm, Ancho: 11 cm, Largo: 32 cm",
    foto: "img/maquinaria/Rotomartillo_Makita_SDS_Plus.webp",
    categoria: "Rotomartillo"
  },
  {
    index: 17,
    titulo: "Generador Inverter Evans 7HP",
    marca: "Evans",
    modelo: "7HP",
    aditamento: "Tipo: Generador Inverter, Potencia: 7 HP, Capacidad del tanque: 15 L, Peso: 45 kg",
    precio: "N/A",
    dimensiones: "Alto: 50 cm, Ancho: 45 cm, Largo: 60 cm",
    foto: "img/maquinaria/Generador_Inverter_Evans_7HP.webp",
    categoria: "Generador"
  },
  {
    index: 18,
    titulo: "Cortadora de Concreto Husqvarna, Motor Honda 11.7 HP",
    marca: "Husqvarna",
    modelo: "Motor Honda 11.7 HP",
    aditamento: "Tipo: Cortadora de Concreto, Potencia: 11.7 HP, Capacidad de corte: 12 cm, Peso: 98 kg",
    precio: "N/A",
    dimensiones: "Alto: 80 cm, Ancho: 50 cm, Largo: 90 cm",
    foto: "img/maquinaria/Cortadora_Concreto_Husqvarna_Honda_11_7HP.webp",
    categoria: "Cortadora"
  }
  ,
  {
    index: 19,
    titulo: "Martillo Demoledor Hexagonal 30.6J 1600W",
    marca: "N/A",
    modelo: "D25941K",
    aditamento: "Tipo: Martillo demoledor, Potencia: 1600W, Energía de impacto: 30.6J, Golpes por minuto: 1620, Peso: 13 kg",
    precio: "N/A",
    dimensiones: "Alto: 17 cm, Ancho: 41.5 cm, Largo: 81 cm",
    foto: "img/maquinaria/Demoledor_Hexagonal.webp",
    categoria: "Demoledor"
  }
];


function Equipment() {
  const [equipmentState, setEquipmentState] = useState([]);
  const [isQuoteCardVisible, setIsQuoteCardVisible] = useState(false);
  const [filteredData, setFilteredData] = useState(equipmentData);

  const addQuote = (equipment) => {
    setEquipmentState((prevList) => [...prevList, equipment]);
    setIsQuoteCardVisible(true);
  };

  const handleOpenQuoteCard = () => {
    setIsQuoteCardVisible(!isQuoteCardVisible);
  };

  const filterByCategory = (category) => {
    if (category === "Todos") {
      setFilteredData(equipmentData);
    } else {
      const filtered = equipmentData.filter(
        (item) => item.categoria === category
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="equipment">
      <a className="quote-icon" style={{ display: "flex" }} onClick={handleOpenQuoteCard}>
        {isQuoteCardVisible ? <IoCloseSharp /> : <BsReceipt />}
      </a>
      <div style={{ backgroundColor: "#f0f0f0" }} className="mt-5 pt-5 pb-5">
        <QuoteCard
          equipmentList={equipmentState}
          setEquipmentList={setEquipmentState}
          isVisible={isQuoteCardVisible}
        />
        <Container>
          <Row>
            <span className="mb-3">
              <img src="/img/icons/arrow.png" style={{ width: "25px", marginRight: "10px" }}></img>
              Nuestros Equipos
            </span>
            <h2>Renta de maquinaria ligera en Nogales</h2>
            <p>
              En R3 Rentas, ofrecemos una amplia gama de equipos para satisfacer
              tus necesidades en proyectos de construcción. Desde plataformas de
              tijera hasta compactadoras y generadores, nuestros equipos están
              diseñados para ofrecerte la máxima eficiencia y rendimiento. A
              continuación, algunos de los equipos disponibles:
            </p>
          </Row>
          <Row>
            <Col className="mb-4">
              <Filters onFilter={filterByCategory} />
            </Col>
          </Row>
          <Row>
            {filteredData.map((item, index) => (
              <Col key={index} md={12} sm={12} lg={12} xl={6} className="mb-4" data-aos="fade-up" data-aos-duration="800">
                <EquipmentCard
                  titulo={item.titulo}
                  marca={item.marca}
                  modelo={item.modelo}
                  aditamento={item.aditamento}
                  precio={item.precio}
                  foto={item.foto}
                  dimensiones={item.dimensiones}
                  addQuote={addQuote}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Equipment;
